<template>
  <div class="mt-6">
    <div class="bg-link-water p-4 rounded-lg">
      <PageTopOuter
        :count="135"
        icon="card_giftcard"
        label="Speaking"
        input-bg="bg-periwinkle-gray"
        :show-dialog="false"
        @on-create="createNewPracticeTest"
      />
    </div>
    <Table :items="combo" @onSelect="onSelect">
        <template slot="row" slot-scope="{ item }" md-selectable="single">
          <md-table-cell>{{ item.testId }}</md-table-cell>
          <md-table-cell>{{ item.title }}</md-table-cell>
          <md-table-cell>
            <span v-if="item.id == 2" class="text-danger rounded px-1 center" style="border: 1px solid #ff4141">
              <md-icon class="m-0 text-base text-danger">warning</md-icon>
              Reported
            </span>
          </md-table-cell>
          <md-table-cell style="min-width: 350px">
            <span class="bg-victoria rounded text-white p-1 mr-1">Practice Item</span>
            <span class="bg-success rounded text-white p-1 mr-1">High Frequency</span>
            <span class="bg-danger rounded text-white p-1 mr-1">No Explanation</span>
          </md-table-cell>
          <md-table-cell>
            <span class="outline-gray-500 py-1 rounded">
              <md-icon class="m-0 text-victoria">bookmark</md-icon>
            </span>
          </md-table-cell>
        </template>
      </Table>
      <div class="flex justify-between align-center my-6">
        <md-button class="bg-victoria text-white text-italic rounded ml-0">Back</md-button>
        <Paginate
        :start="1"
        :end="20"
        :total="100"
        :limit="20"
        />
      </div>
    <Dialog>
      <TestCreationCreate />
    </Dialog>
  </div>
</template>

<script>
import PageTopOuter from "@/components/molecule/PageTopOuter";
import { Dialog, Table, Paginate } from "@/components";
import TestCreationCreate from "@/components/molecule/test-creation/TestCreationCreate";
import data from "@/data/test-creation/data";
import { mapMutations } from "vuex";

export default {
  components: {
    Table,
    Dialog,
    Paginate,
    PageTopOuter,
    TestCreationCreate,
  },
  data() {
    return {
      combo: data.combo
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog"
    }),
    onSelect() {},
    createNewPracticeTest() {
      this.$router.push({
        name: 'dataList.create',
        query: {type: 'speaking'}
      })
    },
  },
};
</script>
